@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.hoverImage {
  img {
    display: block;
    width: 100%;
  }

  > img:last-of-type {
    display: none !important;
  }

  &:hover {
    > img:last-of-type {
      display: block !important;
    }

    > img:first-of-type {
      display: none !important;
    }
  }
}

.borderRadius--s {
  border-radius: var(--border-radius-small);
  img {
    border-radius: var(--border-radius-small);
  }
}

.borderRadius--m {
  border-radius: var(--border-radius);
  img {
    border-radius: var(--border-radius);
  }
}

.borderRadius--l {
  border-radius: var(--border-radius-large);
  img {
    border-radius: var(--border-radius-large);
  }
}
